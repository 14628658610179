import React, { useState } from "react";
import NavLink from "../NavLink";
import styled from "styled-components";
import { THEME_COLOR, CONTRAST_COLOR } from "../../globals/logoColorPalette";
import {
  SOCIAL_MEDIA_BAR_HEIGHT,
  NAV_BAR_HEIGHT,
  MOBILE_BREAKPOINT,
} from "../../globals/magicNumbers";
import ContainerFluid from "../ContainerFluid";
import Burger from "../BurgerButton";
import DropdownMenu from "../DropdownMenu";
import useWindowDimensions from "../../hooks/windowDimensions";

import logo from "../../assets/logo.webp";

const NavBar = ({ navLabels }) => {
  const { width } = useWindowDimensions();
  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <Nav id="navbar">
      <a href="#top" style={{ width: "fit-content" }}>
        <NavLogo src={logo} alt="Chalé du Chef Restaurante" />
      </a>
      {(width > MOBILE_BREAKPOINT && (
        <ContainerFluid>
          {navLabels.map((label) => (
            <NavLink key={label} label={label} />
          ))}
        </ContainerFluid>
      )) || <Burger isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />}
      <DropdownMenu
        labels={navLabels}
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
      />
    </Nav>
  );
};

const Nav = styled.nav`
  background-color: ${THEME_COLOR};
  color: ${CONTRAST_COLOR};
  height: ${NAV_BAR_HEIGHT};
  top: ${SOCIAL_MEDIA_BAR_HEIGHT};
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10%;
  padding-left: 10%;
  box-sizing: border-box;
  z-index: 999;
  border-bottom: 2px white solid;
  border-top: 2px white solid;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-right: 5%;
    padding-left: 5%;
  }
`;

const NavLogo = styled.img`
  height: 100%;
  width: 150px;
  box-sizing: border-box;
  outline: none;
`;

export default NavBar;
