import React from "react";
import styled from "styled-components";
import { primaryFont } from "../../globals/fonts";

import { CONTRAST_COLOR, THIRD_COLOR } from "../../globals/logoColorPalette";

const MenuItem = ({ text, href, onClick }) => {
  return (
    <Link href={href} onClick={onClick}>
      {text}
    </Link>
  );
};

const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: max(30px, 1.8vh);
  font-family: ${primaryFont};
  font-display: swap;
  height: fit-content;
  width: 100%;
  outline: none;
  text-decoration: none;
  padding: 5px;
  box-sizing: border-box;
  border: 1px ${THIRD_COLOR} solid;
  color: ${CONTRAST_COLOR};
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export default MenuItem;
