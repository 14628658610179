import React from "react";
import ReactDOM from "react-dom";
import "./reset.css";
import Main from "./components/Main";

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("main-container")
);
