import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../globals/magicNumbers";
import { primaryFont, secondaryFont } from "../../globals/fonts";

export const StyledH1 = styled.h1`
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${primaryFont};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  text-shadow: ${({ textShadow }) => textShadow};
  text-align: ${({ align }) => align};
`;

export const StyledUL = styled.ul`
  padding-left: 8%;
  font-size: 1.1rem;
  font-family: ${secondaryFont};
  text-align: justify;
  list-style-type: disc;
  color: ${({ color }) => color};
  line-height: 1.5;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.8rem;
  }
`;
