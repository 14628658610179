import React from "react";
import styled from "styled-components";
import ClickableSocialMedia from "../ClickableSocialMedia";
import ContainerFluid from "../ContainerFluid";
import { THIRD_COLOR, CONTRAST_COLOR } from "../../globals/logoColorPalette";
import {
  SOCIAL_MEDIA_BAR_HEIGHT,
  MOBILE_BREAKPOINT,
} from "../../globals/magicNumbers";

const SocialMediaBar = (props) => {
  return (
    <MediaBar id="social">
      <ContainerFluid>
        {props.socialMediaInfo.map((socialMedia) => (
          <ClickableSocialMedia key={socialMedia.name} props={socialMedia} />
        ))}
      </ContainerFluid>
    </MediaBar>
  );
};

const MediaBar = styled.nav`
  color: ${CONTRAST_COLOR};
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  background-color: ${THIRD_COLOR};
  height: ${SOCIAL_MEDIA_BAR_HEIGHT};
  position: fixed;
  top: 0;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  z-index: 999;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-right: 5%;
    padding-left: 5%;
    justify-content: center;
  }
`;

export default SocialMediaBar;
