import styled from "styled-components";

const ContainerFluid = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  justify-content: ${({ justify }) => (justify ? justify : "space-between")};
  align-items: ${({ align }) => (align ? align : "flex-start")};
`;

export default ContainerFluid;
