import React from "react";
import { StyledH1, StyledUL } from "../components/CommonStyled";
import { THEME_COLOR } from "../globals/logoColorPalette";

import ButtonLink from "../components/ButtonLink";
import FixedSocialButton from "../components/FixedSocialButton";
import MenuLikeParagraph from "../components/MenuLikeParagraph";

const panelas =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/panelas_z7yha4.webp";
const churrasqueiraimg =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/churrasqueira_yqugaz.webp";
const docedeleiteimg =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/doce_de_leite_w9wy1p.webp";
const paolinguicaimg =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/pao_linguica_u76lqh.webp";
const cachacaimg =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/cachaca_jy7zyj.webp";
const cortacarneimg =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/corta_carne_fnzurb.webp";
const queijos =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/queijos_wqri6v.webp";
const front_photo =
  "https://res.cloudinary.com/dot2so3s1/image/upload/-replace-/front_photo_mmqmof.webp";

const Slides = React.lazy(() => import("../components/Slides"));
const IframeFluid = React.lazy(() => import("../components/IframeFluid"));

const ContactForm = React.lazy(() =>
  import("../components/KingHostContactForm")
);
const BusinessHours = React.lazy(() => import("../components/BusinessHours"));

const productSlides = [
  {
    background: `${paolinguicaimg}`,
    alt: "Venha provar nosso famoso pão com linguiça.",
  },
  {
    background: `${churrasqueiraimg}`,
    alt: "Nosso forno a lenha para preparar os melhores pratos.",
  },
  {
    background: `${docedeleiteimg}`,
    alt: "Os melhores doces de leite da região.",
  },
  {
    background: `${cortacarneimg}`,
    alt: "Venha saborear nosso delicioso churrasco.",
  },
  {
    background: `${cachacaimg}`,
    alt: "Contamos também com ótimas cachaças regionais.",
  },
  {
    background: `${queijos}`,
    alt: "Temos ainda diversas opções dos melhores queijos.",
  },
];

export default [
  {
    id: "RESTAURANTE",
    h: "100vh",
    background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${front_photo})`,
    rowdirection: "column",
    zIndex: 0,
    subdivs: [
      {
        align: "center",
        justify: "center",
        background: "transparent",
        content: (
          <>
            <StyledH1
              fontSize="4vh"
              color="rgba(255,255,255, 0.8)"
              margin="0px 0px 200px 0px"
              letterSpacing="5px"
              align="center"
            >
              COMIDA BOA E AMBIENTE AGRADÁVEL NA FERNÃO DIAS
            </StyledH1>
            <ButtonLink target="#SOBRE NÓS" text="SAIBA MAIS" size="2vh" />
          </>
        ),
      },
    ],
  },
  {
    id: "SOBRE NÓS",
    h: "100vh",
    background: "white",
    rowdirection: "row",
    columndirection: "column-reverse",
    zIndex: 1,
    subdivs: [
      {
        background: "transparent",
        content: (
          <>
            <MenuLikeParagraph
              title="SOBRE NÓS"
              text={[
                "Somos um típico restaurante de beira de estrada, contando com o melhor da culinária local e oferecendo um amplo cardápio de almoço e lanches.",
                "Desde nossa fundação, em 2012, assumimos a missão de oferecer a nossos clientes um ambiente de conforto e acolhimento, de forma que possam descansar e saborear melhor nossas delícias.",
                "Produtos que oferecemos:",
              ]}
              textColor={THEME_COLOR}
              barsColor={THEME_COLOR}
              minHeight="90%"
              content={
                <>
                  <StyledUL color={THEME_COLOR}>
                    <li>Almoço completo com churrasco.</li>
                    <li>
                      Empório com o que há de melhor da comida mineira: doces,
                      cachaças, queijos, café e quitandas típicas produzidas em
                      nossa cozinha.
                    </li>
                    <li>
                      Utensílios: panelas de ferro, panelas de barro, imagens
                      sacras em madeira e em gesso, colher de pau, e muitos
                      outros.
                    </li>
                    <li>Nosso famoso pão com linguiça.</li>
                  </StyledUL>
                  <ButtonLink
                    target="#COMO CHEGAR"
                    text="DESCUBRA COMO CHEGAR"
                    size="1.5vh"
                  />
                </>
              }
            />
          </>
        ),
      },
      {
        content: (
          <>
            <FixedSocialButton
              link="https://www.instagram.com/chaleduchef/"
              position={["5%", "5%"]}
              content={
                <div>
                  Veja mais em nosso <i className="fa fa-instagram" /> Instagram
                </div>
              }
            />
            <Slides
              slides={productSlides}
              border={`2px ${THEME_COLOR} solid`}
            />
          </>
        ),
      },
    ],
  },
  {
    id: "COMO CHEGAR",
    h: "60vh",
    background: "white",
    columndirection: "column",
    zIndex: 0,
    subdivs: [
      {
        height: "fit-content",
        background: `${THEME_COLOR}`,
        content: <BusinessHours />,
      },
      {
        background: "transparent",
        content: (
          <IframeFluid source="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d932.7399263090225!2d-44.77547660660591!3d-20.752427241751224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa065b826f2dc8f%3A0x5d9f57ebf2c7e3f1!2sChal%C3%A9%20du%20chef!5e0!3m2!1spt-BR!2sbr!4v1590733246394!5m2!1spt-BR!2sbr" />
        ),
      },
    ],
  },
  {
    id: "CONTATO",
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${panelas})`,
    rowdirection: "row",
    zIndex: 0,
    subdivs: [
      {
        background: "transparent",
      },
      {
        background: "transparent",
        content: (
          <ContactForm
            target="contato@chaleduchef.com.br"
            redirect="https://chaleduchef.com.br/"
          />
        ),
      },
    ],
  },
];
