import React, { Suspense } from "react";
import styled from "styled-components";

import SocialMediaBar from "./SocialMediaBar";
import NavBar from "./NavBar";
import Section from "./Section";
import Footer from "./Footer";
import Loading from "./Loading";
import socialMediaInfo from "../globals/socialMediaInfo";
import sectionsInfo from "../globals/sectionsInfo";
import navLabels from "../globals/navLabels";

const Main = () => {
  return (
    <Suspense fallback={<Loading />}>
      <StyledContainer id="home">
        <SocialMediaBar socialMediaInfo={socialMediaInfo} />
        <NavBar navLabels={navLabels} />
        {sectionsInfo.map((section) => (
          <Section key={section.id} content={section} />
        ))}
        <Footer company="Chalé du Chef Restaurante" />
      </StyledContainer>
    </Suspense>
  );
};

const StyledContainer = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Main;
