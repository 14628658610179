import React from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../globals/magicNumbers";
import { primaryFont, secondaryFont } from "../../globals/fonts";

const MenuLikeParagraph = ({
  title,
  text,
  textColor,
  barsColor,
  textAlign,
  content,
  minHeight,
}) => {
  return (
    <StyledParagraphWrapper barsColor={barsColor} minHeight={minHeight}>
      {title && <StyledTitle textColor={textColor}>{title}</StyledTitle>}
      {text.map((p, i) => (
        <StyledParagraph key={i} textColor={textColor} textAlign={textAlign}>
          {p}
        </StyledParagraph>
      ))}
      {content && content}
    </StyledParagraphWrapper>
  );
};

const StyledParagraphWrapper = styled.div`
  padding: 0 5% 0% 5%;
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 0)};
  height: fit-content;
  box-sizing: border-box;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-top: 2px ${({ barsColor }) => (barsColor ? barsColor : "none")} solid;
    border-bottom: 2px ${({ barsColor }) => (barsColor ? barsColor : "none")}
      solid;
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    border-right: 2px ${({ barsColor }) => (barsColor ? barsColor : "none")}
      solid;
    border-left: 2px ${({ barsColor }) => (barsColor ? barsColor : "none")}
      solid;
  }
`;

const StyledTitle = styled.h2`
  align-self: center;
  min-width: fit-content;
  width: 60%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 8px;
  font-size: 2rem;
  font-family: ${primaryFont};
  font-display: swap;
  font-weight: bold;
  color: ${({ textColor }) => textColor};
  border-bottom: 2px ${({ textColor }) => textColor} solid;
  padding-bottom: 15px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: fit-content;
    font-size: 1.3rem;
  }
`;

const StyledParagraph = styled.p`
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-family: ${secondaryFont};
  font-display: swap;
  color: ${({ textColor }) => textColor};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "justify")};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: fit-content;
    font-size: 0.8rem;
  }
`;
export default MenuLikeParagraph;
