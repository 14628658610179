import React from "react";
import styled from "styled-components";
import { THEME_COLOR, CONTRAST_COLOR } from "../../globals/logoColorPalette";
import { MOBILE_BREAKPOINT } from "../../globals/magicNumbers";
import { primaryFont } from "../../globals/fonts";

const ButtonLink = ({ target, size, text }) => {
  return (
    <StyledButtonLink href={target} size={size}>
      {text}
    </StyledButtonLink>
  );
};

const StyledButtonLink = styled.a`
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  letter-spacing: 5px;
  outline: none;
  font-size: ${({ size }) => size};
  font-family: ${primaryFont};
  color: ${CONTRAST_COLOR};
  background-color: ${THEME_COLOR};
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0.8;
  box-shadow: none;
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 1;
    box-shadow: 0 0 1px 1px ${CONTRAST_COLOR};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    opacity: 1;
    box-shadow: 0 0 1px 1px ${CONTRAST_COLOR};
  }
`;

export default ButtonLink;
