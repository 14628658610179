import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ContentDiv from "../ContentDiv";
import {
  MOBILE_BREAKPOINT,
  TOTAL_NAV_HEIGHT,
} from "../../globals/magicNumbers";
import useWindowDimensions from "../../hooks/windowDimensions";
const Section = ({ content }) => {
  const [deviceWidth, setDeviceWidth] = useState(2440);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setDeviceWidth(width);
    //eslint-disable-next-line
  }, []);
  return (
    <StyledSection
      id={content.id}
      h={content.h}
      w={content.w}
      background={content.background.replace(
        "-replace-",
        `w_${deviceWidth >= 813 ? 2440 : 3 * deviceWidth}`
      )}
      zIndex={content.zIndex}
      d={
        (width > MOBILE_BREAKPOINT && content.rowdirection) ||
        content.columndirection ||
        "column"
      }
    >
      {content.subdivs.map((subdiv, i) => (
        <ContentDiv key={i} content={subdiv}></ContentDiv>
      ))}
    </StyledSection>
  );
};

const StyledSection = styled.section`
  padding-top: ${TOTAL_NAV_HEIGHT};
  height: ${({ h }) => (h ? h : "fit-content")};
  width: ${({ w }) => (w ? w : "100%")};
  min-height: fit-content;
  position: relative;
  display: flex;
  flex-direction: ${({ d }) => d};
  box-sizing: border-box;
  z-index: ${(zIndex) => zIndex};
  background-image: ${({ background }) => (background ? background : "none")};
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: 40% 50%;
  background-blend-mode: darken;
`;

export default Section;
