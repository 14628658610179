export default [
  {
    name: "Facebook",
    url: "https://www.facebook.com/ChaleduChef/",
    icon: "facebook-square",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/chaleduchef/",
    icon: "instagram",
  },
  {
    name: "(37) 99871-1516",
    url: "tel:+5537998711516",
    icon: "phone",
  },
];
