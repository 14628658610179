import React from "react";
import styled from "styled-components";
import { THEME_COLOR, CONTRAST_COLOR } from "../../globals/logoColorPalette";
import { secondaryFont } from "../../globals/fonts";

const Footer = ({ company }) => {
  const year = new Date().getFullYear();
  return (
    <StyledFooter>
      <p>
        {company} © {year}. Todos os direitos reservados.
      </p>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  width: inherit;
  box-sizing: border-box;
  background-color: ${THEME_COLOR};
  color: ${CONTRAST_COLOR};
  height: fit-content;
  text-align: center;
  font-family: ${secondaryFont};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10% 5px 10%;
`;

export default Footer;
