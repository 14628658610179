import React from "react";
import styled from "styled-components";
import MenuLink from "../MenuLink";
import useWindowDimensions from "../../hooks/windowDimensions";

import { THEME_COLOR } from "../../globals/logoColorPalette";
import { NAV_BAR_HEIGHT, MOBILE_BREAKPOINT } from "../../globals/magicNumbers";
const Menu = ({ labels, isMenuOpen, setMenuOpen }) => {
  const { width } = useWindowDimensions();
  return (
    <StyledMenu isMenuOpen={isMenuOpen && width <= MOBILE_BREAKPOINT}>
      {labels.map((label) => {
        return (
          <MenuLink
            text={label}
            href={`#${label}`}
            aria-label={label}
            key={label}
            onClick={() => setMenuOpen(false)}
          />
        );
      })}
    </StyledMenu>
  );
};

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${THEME_COLOR};
  height: fit-content;
  text-align: center;
  letter-spacing: 10px;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-out;
  transform: ${({ isMenuOpen }) =>
    isMenuOpen
      ? `translateY(calc(${NAV_BAR_HEIGHT} - 2px))`
      : "translateY(-200%)"};
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 100%;
  }
`;

export default Menu;
