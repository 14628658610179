import React from "react";
import styled from "styled-components";
import { CONTRAST_COLOR } from "../../globals/logoColorPalette";

const Burger = ({ isMenuOpen, setMenuOpen }) => {
  return (
    <StyledBurger
      isMenuOpen={isMenuOpen}
      onClick={() => setMenuOpen(!isMenuOpen)}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const StyledBurger = styled.button`
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${CONTRAST_COLOR};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? "rotate(45deg)" : "rotate(0)"};
      background: ${({ isMenuOpen }) => (isMenuOpen ? "#DDD" : "#FFF")};
    }

    :nth-child(2) {
      opacity: ${({ isMenuOpen }) => (isMenuOpen ? "0" : "1")};
      width: ${({ isMenuOpen }) => (isMenuOpen ? "0" : "2rem")};
    }

    :nth-child(3) {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? "rotate(-45deg)" : "rotate(0)"};
      background: ${({ isMenuOpen }) => (isMenuOpen ? "#DDD" : "#FFF")};
    }
  }
`;

export default Burger;
