import React from "react";
import styled from "styled-components";

const ContentDiv = ({ content }) => {
  return (
    <StyledContentDiv
      height={content.height}
      width={content.width}
      background={content.background}
      direction={content.direction}
      color={content.color}
      align={content.align}
      justify={content.justify}
    >
      {(content.content && content.content) || <> </>}
    </StyledContentDiv>
  );
};

const StyledContentDiv = styled.div`
  height: ${({ height }) => (height ? height : "100%")};
  width: ${({ width }) => (width ? width : "100%")};
  background: ${({ background }) => (background ? background : "white")};
  color: ${({ color }) => (color ? color : "white")};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  align-items: ${({ align }) => (align ? align : "center")};
  box-sizing: border-box;
  z-index: inherit;
`;

export default ContentDiv;
