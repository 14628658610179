import React from "react";
import styled from "styled-components";
import { CONTRAST_COLOR } from "../../globals/logoColorPalette";
import { primaryFont } from "../../globals/fonts";

const NavLink = ({ label }) => {
  return (
    <Link href={`#${label}`} aria-label={label}>
      {label}
    </Link>
  );
};

const Link = styled.a`
  color: ${CONTRAST_COLOR};
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-right: 30px;
  text-decoration: none;
  text-align: center;
  letter-spacing: 2px;
  font-family: ${primaryFont};
  font-display: swap;
  &:hover {
    text-decoration: underline;
  }
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

export default NavLink;
