import React from "react";
import styled from "styled-components";
import { primaryFont } from "../../globals/fonts";
import { THEME_COLOR, CONTRAST_COLOR } from "../../globals/logoColorPalette";
import { MOBILE_BREAKPOINT } from "../../globals/magicNumbers";

const FixedSocialButton = ({ link, content, position }) => {
  return (
    <StyledAnchor
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      position={position}
    >
      {content}
    </StyledAnchor>
  );
};

const StyledAnchor = styled.a`
  position: absolute;
  box-sizing: border-box;
  font-size: 1.1rem;
  font-family: ${primaryFont};
  text-align: center;
  top: ${({ position }) => position[0]};
  left: ${({ position }) => position[1]};
  width: 90%;
  height: fit-content;
  padding: 10px;
  color: ${CONTRAST_COLOR};
  background-color: ${THEME_COLOR};
  border: none;
  text-decoration: none;
  z-index: 2;
  transition: letter-spacing 0.2s linear;
  outline: none;
  &:active,
  &:focus {
    outline: none;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: relative;
    top: 0;
    left: 0;
  }
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    &:hover {
      letter-spacing: 2px;
    }
  }
`;

export default FixedSocialButton;
