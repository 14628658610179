import React from "react";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/windowDimensions";
import { CONTRAST_COLOR } from "../../globals/logoColorPalette";
import { MOBILE_BREAKPOINT } from "../../globals/magicNumbers";
import { primaryFont } from "../../globals/fonts";

const ClickableSocialMedia = ({ props }) => {
  const { width } = useWindowDimensions();
  return (
    <SocialMediaLink
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      id={`${props.name}-a`}
    >
      <i className={`fa fa-${props.icon}`} style={{ marginRight: "5px" }} />
      {width > MOBILE_BREAKPOINT && props.name}
    </SocialMediaLink>
  );
};

const SocialMediaLink = styled.a`
  background-color: inherit;
  color: ${CONTRAST_COLOR};
  outline: none;
  text-decoration: none;
  margin: 30px;
  font-size: 1.2rem;
  font-family: ${primaryFont};
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin: 15px;
  }
`;

export default ClickableSocialMedia;
